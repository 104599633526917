var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c0859ccdc4f523b7410560ce374cf4bb69e634e0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const environment =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ? "production"
    : process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
      ? "staging"
      : process.env.NEXT_PUBLIC_VERCEL_ENV ?? "development";

let tracesSampleRate;
let replaysOnErrorSampleRate;
let replaysSessionSampleRate;

switch (environment) {
  case "production":
    tracesSampleRate = 0.1;
    replaysOnErrorSampleRate = 0.1;
    replaysSessionSampleRate = 0.1;
    break;
  case "staging":
    tracesSampleRate = 0.1;
    replaysOnErrorSampleRate = 0.1;
    replaysSessionSampleRate = 0.1;
    break;
  case "development":
  default:
    tracesSampleRate = 0;
    replaysOnErrorSampleRate = 0;
    replaysSessionSampleRate = 0;
}

Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: "https://d0a7937ff41d48c9d83a1dabbb29276c@o1116256.ingest.us.sentry.io/4507254058778624",
  environment: environment,
  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: true,
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
    }),
  ],

  replaysOnErrorSampleRate: replaysOnErrorSampleRate,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: replaysSessionSampleRate,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: tracesSampleRate,
});
